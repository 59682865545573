<template>
  <div><h1>Creating user session...</h1></div>
</template>
<script>
import store from '@/store'

export default {
  mounted () {
    this.setToken()
  },
  methods: {
    setToken () {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      store.commit('setToken', params.token)
      store.dispatch('fetchLocalUser')
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
